import React,{useEffect,useState} from 'react'
import Navbar from '../../../ConsumerDashboard/Reusable/Navbar/Navbar'
import ContributorSidebar from '../../Reusable/ContributorSidebar/ContributorSidebar'
import ContributionHeader from '../../Reusable/ContributionHeader/ContributionHeader'
import ResourceTable from '../../Reusable/ResourceTable/ResourceTable'
import { useSelector } from 'react-redux';
import axios from 'axios';
import DashboardLoader from '../../../ConsumerDashboard/Reusable/DashboardLoader/DashboardLoader'


const SubmittedContributions = () => {
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [docData,setDocData] = useState([]);
  const [loading,setLoading] = useState(true);

  const getDocuments = () => {
  
  const queryParams = {};
    
  queryParams.contributor_id = userData.id;

  try{ 

    
    const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Authorization header
          },
      };
  
    axios.get(`${baseUrl}documents/contributor`, config)
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        setDocData(response.data.data);
        
      
      })
      .catch((error) => {
        setLoading(false);
       if (error.response) {
        if (error.response.data.message === "Please login again.") {
            localStorage.clear(); // Clear local storage
            window.location.href = '/login'; // Redirect to login page
        } else {
            alert(error.response.data.message);
        }
        } else if (error.request) {
            alert("Check your connection. Unable to reach the server.");
        } else {
            alert("An error occurred. Please try again later.");
        }
      });
    }
    catch(error){
      if (error.response) {
          alert(error.response.data.message);
      } else if (error.request) {
          alert("Check your connection. Unable to reach the server.");
      } else {
         alert("An error occurred. Please try again later.");
      }
    }
  };

   useEffect(()=>{   
        getDocuments()
      // eslint-disable-next-line
      },[]);

  return (
    <div className='dashboard-main'>

        <div>
         <ContributorSidebar/>
        </div>

        <section>
            <Navbar/>
            <ContributionHeader/>
            {loading ?
            <DashboardLoader/>
            :
            <>
            {docData.length === 0 ?
            <h2 style={{textAlign:'center',marginTop:'50px'}}>No contributions submitted yet</h2> :
            <ResourceTable
            tableData={docData}
            />}
            </>
            }
        </section>
     </div>
  )
}

export default SubmittedContributions
