import React from 'react';
import './PdfViewer.scss';
import report from '../../../../assets/reportpdf.png';
import { PiWarningBold } from "react-icons/pi";
import { AiOutlineLink } from "react-icons/ai";
import { RxDownload } from "react-icons/rx";
import { FiBookmark } from "react-icons/fi";
import axios from 'axios';
import { useSelector } from 'react-redux';

const PdfViewer = ({docInfo,refreshDoc,pdfUrl}) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token;
   
    function formatDateLong(isoString) {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }


    const getDownload = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Authorization header
                },
            };

            const response = await axios.get(`${baseUrl}documents/consumer/${docInfo.id}/download`, config);
            const url = response.data.data.url;

            const link = document.createElement('a');
            link.href = url;
            // link.download = docInfo.title; // Optional: Set a default file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
            } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
            } else {
                alert("An error occurred. Please try again later.");
            }
        }
    };

    const getBookmark = () => {

        try{ 
      
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`, // Authorization header
                },
            };
        
          axios.get(`${baseUrl}documents/consumer/${docInfo.id}/bookmark`, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message)
              refreshDoc();
             
            })
            .catch((error) => {
              
                if (error.response) {
                    if (error.response.data.message === "Please login again.") {
                        localStorage.clear(); // Clear local storage
                        window.location.href = '/login'; // Redirect to login page
                    } else {
                        alert(error.response.data.message);
                    }
                } else if (error.request) {
                    alert("Check your connection. Unable to reach the server.");
                } else {
                    alert("An error occurred. Please try again later.");
                }
            });
          }
          catch(error){
            if (error.response) {
                alert(error.response.data.message);
            } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
            } else {
               alert("An error occurred. Please try again later.");
            }
          }
    };


    return (

        <div className='pdf-backgd'>

            <div className='pdf-main-cont'>

                <div className='pdf-cont-header'>

                    <div className='pdf-header-left'>

                        <div>
                         <img src={report} alt="" />
                        </div>

                        <div>
                            <h4>{docInfo.title}</h4>
                            <p className='pdf-left-tag'>
                                <li>{docInfo.industries.map(industry => industry.name).join(', ')}</li>
                                <p>Last Updated: {formatDateLong(docInfo.updated_at)}</p>
                            </p>
                        </div>

                    </div>

                    <div className='pdf-header-btn'>

                        <div >
                            <h4
                            onClick={()=>window.location.href=`/consumer/documents/home/viewdocument/${docInfo.id}`}
                            className='pdf-header-close-btn'
                            >Close Preview</h4>
                        </div>

                        <div className='pdf-header-report-btn'>
                            <PiWarningBold
                            color='#D42620'
                            fontWeight={"600"}
                            />
                            <h4>Report</h4>
                        </div>
                    </div>

                </div>


                <section className='pdf-section' >

                   

                    <div className='pdf-iframe-cont'>
                    
                    <iframe
                        src={pdfUrl}
                        onContextMenu={(e) => e.preventDefault()} // Disable right-click
                        title="PDF Viewer"
                        style={{pointerEvents:"none",width: '100%', height: '100vh', border: 'none',background:"#F6F6F6",padding:'40px' }}
                     >

                    </iframe>

                   
                    
                    </div>

                    <div>

                        <div className='pdf-icons'>
                            <AiOutlineLink
                            size={18}
                            />
                        </div>

                        <div 
                        onClick={()=>getDownload()}
                        className='pdf-icons'>
                            <RxDownload
                            
                            size={18}
                            />
                        </div>

                        <div className='pdf-icons'>
                            <FiBookmark
                            color={docInfo.is_bookmarked ? "#007EFF":""}
                            onClick={()=>getBookmark()}
                            size={18}
                            />
                        </div>

                    </div>
                </section>

                

                    
            </div>
     
        </div>
    );
};

export default PdfViewer;
