import React, { useState,useContext } from 'react';
import Header from '../Header/Header';
import OnboardingSidebar from '../../Reusables/OnboardingSidebar/OnboardingSidebar';
import './MoreAboutOne.scss';
import  upload from '../../../../assets/file upload states.svg';
import checkedIcon from '../../../../assets/checked.svg';
import uncheckedIcon from '../../../../assets/uncheck.svg';
import CustomDropdown from '../../Reusables/SelectDropdown/SelectDropdown';
import ProgressBar from '../../Reusables/ProgressBar/ProgressBar';
import { multiStepContext } from '../../Reusables/ProgressBar/StepContext';
import BaseButton from '../../Reusables/BaseButton/BaseButton';
import { useEffect } from 'react';

const MoreAboutOne = () => {

const { setStep } = useContext(multiStepContext);
const [selectedUserType, setSelectedUserType] = useState('');
const [image, setImage] = useState(null);
const [selectedOption, setSelectedOption] = useState('');
const [selectedIndustry, setSelectedIndustry] = useState('');
const [companyName,setCompanyName]=useState('');
const [companyRole,setCompanyRole] = useState('');
const [companySize,setCompanySize] = useState(null);





const MAX_WIDTH = 800;
const MAX_HEIGHT = 400;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

  const validateImage = (file) => {
    return new Promise((resolve, reject) => {
      if (file.size > MAX_FILE_SIZE) {
        reject("File size must be less than 5 MB");
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
          reject(`Image dimensions should not exceed ${MAX_WIDTH}x${MAX_HEIGHT}px`);
        } else {
          resolve();
        }
      };
    });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      validateImage(file)
        .then(() => {
          const reader = new FileReader();
          reader.onloadend = () => {
          const base64String = reader.result;
          setImage(base64String);
          sessionStorage.setItem('image', base64String);
        };
        reader.readAsDataURL(file);
        const imageApi = URL.createObjectURL(file)
        sessionStorage.setItem('apiImage', imageApi);
          
        })
        .catch((err) => alert(err));
    };
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.classList.add('drag-over');
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.classList.remove('drag-over');
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.classList.remove('drag-over');

    const file = event.dataTransfer.files[0];
    if (file) {
      validateImage(file)
        .then(() => {
          const reader = new FileReader();
          reader.onloadend = () => {
          const base64String = reader.result;
          setImage(base64String);
          sessionStorage.setItem('image', base64String);
        };
        reader.readAsDataURL(file);
        const imageApi = URL.createObjectURL(file)
        sessionStorage.setItem('apiImage', imageApi);
        })
        .catch((err) => alert(err));
    }
  };

  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
  };

  
 
  const options=[
    'Student',
    'Business Owner',
    'Employed'
  ];

  
  const optionsIdustry=[
    'Construction',
    'Education',
    'Financial Services',
    'Government',
    'Information Technology',
    'Manufacturing',
    'Non-profit',
    'Petroleum',
    'Power',
    'Renewable Energy',
    'Others'
  ];


  const handleSelect = (userType) => {
    setSelectedUserType(userType);
    sessionStorage.setItem('user_type', userType);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    sessionStorage.setItem('occupation',option);
  };

  const handleCompanyName = (e) => {
      const newCompanyName = e.target.value;
      setCompanyName(newCompanyName);
      sessionStorage.setItem('companyName', newCompanyName);
  };


  const handleCompanyRole = (e) => {
      const newCompanyRole = e.target.value;
      setCompanyRole(newCompanyRole);
      sessionStorage.setItem('companyRole', newCompanyRole);
  };
  const handleCompanySize = (e) => {
      const newCompanySize = e.target.value;
      setCompanySize(newCompanySize);
      sessionStorage.setItem('companySize', newCompanySize);
  };
  
  
  const handleSelectIndustry = (option) => {
    setSelectedIndustry(option);
    sessionStorage.setItem('industry',option);
  };

  const clearImage=()=>{
    setImage(null)
    sessionStorage.removeItem('image');
    sessionStorage.removeItem('apiImage');
    
  }

 

 

  const handleSubmit=()=>{
    if(!selectedUserType){
      alert("select user type ")
    }
      
    else if(selectedUserType==='individual')
    {
      if(!selectedOption){
        alert("select occupation")
        return
      }
      else{
        setStep(2)
      }
    }
    else if(selectedUserType==='corporate'){
    if(!companyName || !companyRole || !companySize )
      {
      alert("all fields required")
      return
    }
    else{
      setStep(2)
    }
  }
  }

  useEffect(()=>{

   
    setCompanyName(sessionStorage.getItem('companyName') || '');
    setCompanyRole(sessionStorage.getItem('companyRole') || '');
    setImage(sessionStorage.getItem('image') || null)
    setCompanySize(sessionStorage.getItem('companySize') || null)
    setSelectedIndustry(sessionStorage.getItem('industry') || '')
    setSelectedUserType(sessionStorage.getItem('user_type') || '')
    setSelectedOption(sessionStorage.getItem('occupation') || '')

  },[])
   
  

  return (
    <div className='onboard_cont'>
        <div>
            <OnboardingSidebar/>
        </div>

       <div className='more_about_one_cont'>
   
            <Header/>
            
            <div>
              <ProgressBar/>
            </div>
           
            
                <p className='more_about_one_pp_header'>Profile photo</p>

                {image ? (
                    <div className='uploaded_image'>
                        <div>
                        <img src={image} alt="Uploaded Preview" />
                        </div>
                      

                      <button 
                      onClick={()=>clearImage()}
                      className='more_about_btn'>remove image</button>
                    </div>
                
                ):(
                    <div 
                    className="more_about_one_pp"
                    onClick={triggerFileInput}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    >
                    
                      <div>
                          <div>
                              
                          <img src={upload} alt="Upload Icon" />
                          
                          </div>

                          <input
                              type="file"
                              id="fileInput"
                              onChange={handleImageUpload}
                              style={{ display: 'none' }}
                              accept=".svg, .png, .jpg, .jpeg"
                          />
                              <>
                              <p className="click_drag_upload">
                              Click to upload <span>or drag and drop</span>
                              </p>
                              <p className="click_svg">SVG, PNG, JPG (max. 800x400px)</p>
                              </>
          
                      </div>
                       
                        
                    </div>
                )}

               
            

            <div className='more_about_user_type'>
                <p>User type</p>

                <div className='more_about_user_type_ioc' >
                    <div 
                    onClick={() => handleSelect('individual')}
                    className={`more_about_user_type_box ${selectedUserType === 'individual' ? 'active' : ''}`}
                    >
                        <div>
                            <img 
                             src={selectedUserType === 'individual' ? checkedIcon : uncheckedIcon} 
                             alt="icon" />
                        </div>
                    
                        <div>
                            <p>Individual</p>
                            <p>For personal use</p>
                        </div>
                    </div>

                    <div 
                     onClick={() => handleSelect('corporate')}
                     className={`more_about_user_type_box ${selectedUserType === 'corporate' ? 'active' : ''}`}
                    >
                        <div>
                        <img
                         src={selectedUserType === 'corporate' ? checkedIcon : uncheckedIcon} 
                         alt="icon" />
                        </div>
                    
                        <div>
                            <p>Corporate</p>
                            <p>For organizational use</p>
                        </div>
                    </div>
                </div>

                {selectedUserType === 'individual'? 
                <div className='more_about_wdyd'>
                    <p>What do you do? </p>
                    <CustomDropdown
                    options={options}
                    selectedOption={selectedOption}
                    placeholder={"Select occupation (Student, Business owner or Employed)"}
                    heightFor="50px"
                    paddingFor="1em"
                    onSelect={handleOptionSelect}
                    />
                  
                </div>
                :""}

                {selectedUserType === 'corporate'?(
                     <div className='more_about_grid'>
                     <div>
                         <p>Company name</p>
                         <input 
                         placeholder='e.g. name@example.com'
                         type="text"
                          value={companyName}
                          onChange={(e)=>handleCompanyName(e)}
                          />
                     </div>
 
                     <div>
                         <p>Your role</p>
                         <input 
                         placeholder='e.g. Research Lead'
                         type="text"
                         value={companyRole}
                         onChange={(e)=>handleCompanyRole(e)}
                         />
                     </div>
                     
                     <div>
                         <p>Company size</p>
                         <input type="number"
                         placeholder='e.g. 20'
                         value={companySize}
                         onChange={(e)=>handleCompanySize(e)}
                         min={1}
                         />
                     </div>
 
                     <div>
                         <p>industry</p>

                      
                         <CustomDropdown
                            options={optionsIdustry}
                            selectedOption={selectedIndustry}
                            placeholder={"Oil and gas"}
                            heightFor="40px"
                            paddingFor="0.5em 1em"
                            onSelect={handleSelectIndustry}
                            heightForTwo={'150px'}
                         />
                         
                         
                     </div>
                 </div>
                ):(
                    ""
                )}

               
                
            <div className='more_about_btn_div'>
                
                <BaseButton
                    title={"Proceed"}
                    width={"160px"}
                    height={"44px"}
                    padding={"0.5em 0.75em"}
                    // onClick={()=>setStep(2)}
                    onClick={()=> handleSubmit()}
                />
                
            </div>
        </div>
      </div> 
    </div>
  )
}

export default MoreAboutOne
