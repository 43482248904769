import React,{useState} from 'react';
import './ContributionHeader.scss'
import { IoSearch } from "react-icons/io5";




const ContributionHeader = () => {
    const [selectedItem,setSelectedItem] = useState("doc");
   

  return (

    <div className='contb-header-cont'>

        <div className='contb-header-box'>

            <h4 className='contb-header'> 
            Submitted Contributions
            </h4>


            <div>
                <div 
                    className='contb-header-search'
                >
                    <IoSearch
                    color='#77787C'
                    />
                    <input 
                    placeholder='Search'
                    type="text" />
                </div>

              
            </div>

        </div>


        <div className='contb-type-box'>
            <div className='contb-type'>
                <h4
                     onClick={()=>setSelectedItem("data")}
                    className={selectedItem === "data" ? "contb-type-active":"contb-type-inactive"}
                >
                    New Data Tables</h4>
                <h4
                     onClick={()=>setSelectedItem("table")}
                    className={selectedItem === "table" ? "contb-type-active":"contb-type-inactive"}
                >
                    Existing Tables
                </h4>
                <h4 
                     onClick={()=>setSelectedItem("doc")}
                    className={selectedItem === "doc" ? "contb-type-active":"contb-type-inactive"}>
                    Documents
                </h4>
            </div>

            
        </div>

        
      
    </div>
  )
}

export default ContributionHeader
