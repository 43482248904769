import React,{useState,useContext,useEffect} from 'react'
import upload from '../../../../assets/file upload states.svg';
import axios from 'axios';
import './DocumentSummary.scss';
import { useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import checkIcon from '../../../../assets/checkIcon.svg';
import checkedIcon from '../../../../assets/checkedIcon.svg';
import uploaded from '../../../../assets/uploadsucess.svg';
import bin from '../../../../assets/bin.svg'
import { CiCalendar } from "react-icons/ci";
import Calendar from '../../../ConsumerDashboard/Reusable/Calendar/Calendar'
import CustomDropdown from '../../../Onboarding/Reusables/SelectDropdown/SelectDropdown';
import { multiStepContext } from '../../../Onboarding/Reusables/ProgressBar/StepContext';
import CountryDropdown from './CountryDropDown/CountryDropDown';
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoIosRadioButtonOff } from "react-icons/io";

const DocumentSummary = ({onSelect}) => {

  const {setStep} = useContext(multiStepContext);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token;
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [selectedWords, setSelectedKeywords] = useState([]);
    const [selectedKeyId, setSelectedKeyId] = useState([]);
    const [industries, setIndustries]= useState([]);
    const [isWordOpen, setIsKeyword] =useState(false);
    const [isLanguageOpen, setLanguageOpen] =useState(false);
    const [isDateOpen, setIsDateOpen] =useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [doctype, setDocType] =  useState('') ;
    const [howDoc, setHowDoc] =  useState('');
    const [docSource, setDocSource] = useState('');
    const [docTitle,setDocTitle] = useState('');
    const [description,setDescription]=useState('');
    const [author,setAuthor]= useState('')
    const [selectedLang,setSelectedLang] = useState('');
    const [selectedLocation, setSelectedLocation] = useState("");

  

   const handleLocation = (selectedValues) => {
    setSelectedLocation(selectedValues); // Update the state with the selected values
    
  };


    function toggleDateOpen (){
        setIsDateOpen(!isDateOpen);
      
      }
    

    function toggleTagOpen (){
        setIsKeyword(!isWordOpen)
    }
    
    function handleDocSelect (option) {
      setDocType(option);
      // sessionStorage.setItem('industry',option);
    }

  
    function handleLanguage (value){
      setSelectedLang(value);
      setLanguageOpen(false)
    }
    function toggleLanguageOpen (){
      setLanguageOpen(!isLanguageOpen);
    
    }    

    function handleDocHow (option) {
      setHowDoc(option);
      // sessionStorage.setItem('industry',option);
    }
    
  const handleDateSelect = (date) => {

    const day = date.getDate();
    const month = date.getMonth() + 1; // Get the month (Note: January is 0)
    const year = date.getFullYear();

    // Format day and month with leading zeros if needed
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    setSelectedDate(formattedDate);

    setIsDateOpen(false)
    };

    function handleKeywordSelect (word) {

    if (selectedWords.includes(word.name)) {
        setSelectedKeywords(selectedWords.filter((item) => item !== word.name));
        
    } else {
        setSelectedKeywords([...selectedWords, word.name]);
    }
    
    if (selectedKeyId.includes(word.id)) {
        
        setSelectedKeyId(selectedKeyId.filter((item) => item !== word.id));
        
    } else {
        
        setSelectedKeyId([...selectedKeyId, word.id]);
        
    }
    
    };
    

    const getIndustry=()=>{
 
        try {
          
          const config = {
            headers: {
              "Content-Type": "application/json",
               'Authorization': `Bearer ${token}`
            },
          };
    
          axios
            .get(`${baseUrl}industry`,config)
            .then((res) => {
           
                setIndustries(res.data.data)
            
            })
            .catch((error) => {
              
             
              if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
            } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
            } else {
                alert("An error occurred. Please try again later.");
            }
            });
        }
        catch (error) {
          console.log("error", error);
        }
    }

    
   function goToconfirmPage (){
        if (
          !file || 
          !selectedKeyId.length || 
          !selectedDate || 
          !doctype || 
          !howDoc || 
          !docSource || 
          !docTitle || 
          !description || 
          !author 
        ) {
          alert("Please fill in all the fields before proceeding.");
          return; 
        }
        else{
        
        onSelect(docData)
        setStep(2);
        window.scrollTo(0, 0);
        }
      }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        handleFileUpload(selectedFile);
      };
    

    
    
      const handleFileUpload = (file) => {
        if (file && file.type === 'application/pdf') {
          if (file.size <= 10 * 1024 * 1024) { // 10MB in bytes
            setFile(file);
            setError('');
          } else {
            setError('File size exceeds 4MB');
          }
        } else {
          setError('Please upload a valid PDF file');
        }
      };
    
      const handleDragOver = (e) => {
        e.preventDefault();
      };
    
      const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        handleFileUpload(droppedFile);
      };
    
      const docData = {
        file,
        docSource,
        doctype,
        author,
        selectedKeyId,
        selectedDate,
        howDoc,
        docTitle,
        description,
        selectedLocation,
        selectedLang
      }

      const docTypeOptions = [
        'Regulations',
        'Standards',
        'Guidelines',
        'Analysis',
        'Research',
        'Periodicals',
        'Others'
      ]

      const sourceOptions = [
        'Social Media',
        'Company Report',
        'I work there',
        'I would rather not  say',
      ]

    

      useEffect(() => {
         getIndustry()
          // eslint-disable-next-line
      },[]);

  return (
    <div className='doc_summary_cont'>

      <div className='doc_summary_header'>
        <h2>Document Summary</h2>
        <p>Fill out these details to describe your report.</p>
      </div>

      <form action="">
        
        <div>
            <h4>Title of document <span style={{color:'red'}}>*</span></h4>
            <input 
             className='doc_summary_cont_title'
            placeholder='Enter title here'
            type="text"
            value={docTitle}
            required
            onChange={(e)=>setDocTitle(e.target.value)}
            />
        </div>
        
        <div>
           <h4>Description<span style={{color:'red'}}>*</span></h4>
           <textarea 
            placeholder='Enter text here'
            name="" 
            id=""
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
           >

           </textarea>

           <p className='doc_summary_helper'> Keep this below 150 words</p>
        </div>

     
            <div style={{position:'relative'}}>
                    <h4>Tags<span style={{color:'red'}}>*</span></h4>
                    
                    <div className='tags_drop' onClick={()=>toggleTagOpen()}>
                        {selectedWords.length > 0 ? <h4> {selectedWords.slice(0,12) + (selectedWords.length > 12 ? "   (+" + (selectedWords.length - 12) + ")" : "")}</h4>: <p>Select Tags</p>}

                        {isWordOpen ? 
                        <IoIosArrowUp
                        color='#9CA0A5'
                        /> :
                        <IoIosArrowDown
                        color='#9CA0A5'
                        
                        />}
                             
                       
                    </div>

                    {isWordOpen && 
                      <div className='create_filter_keywd_main'>
                          <div className='create_filter_keywd'>
                              {industries.map((item,index)=>(
                                  <div className='create_filter_keywd_grid' key={index}>
                                      <div>
                                          {selectedWords.includes(item.name) ? (
                                              
                                              <img 
                                              onClick={()=>handleKeywordSelect(item)}
                                              src={checkedIcon} alt="check" />
                                          ):(
                                              <img 
                                              onClick={()=>handleKeywordSelect(item)}
                                              src={checkIcon} alt="check" />
                                          )}
                                          

                                      </div>
                                      <h5>{item.name}</h5>
                                  </div>                            
                              
                              ))}

                              
                          
                          </div>
                              <div style={{marginTop:'0px'}} className='create_filter_btn'>
                                  <button 
                                  disabled={selectedWords.length === 0}
                                  onClick={()=>setIsKeyword(false)}
                                  >
                                      Select
                                  </button>
                              </div>
                              
                      </div>
                    }
      
        </div>

        <div >
              <h4>Author</h4>
              <input 
                className='doc_summary_cont_author'
                placeholder="Enter author's name"
                type="text" 
                value={author}
                onChange={(e)=>setAuthor(e.target.value)}
              />
        </div>

        <div className='doc-summary-side'>
         

          <div>
                  <h4>Publication Date<span style={{color:'red'}}>*</span></h4>

                  <div className='date_drop' onClick={()=>toggleDateOpen()}>
                          

                    <CiCalendar
                    size={20}
                    />
                    {selectedDate ?  <h4> {selectedDate} </h4> :
                    
                    <p>DD/MM/YYYY</p>}
            
                    </div>
                    
                    {isDateOpen &&  
                    <Calendar
                    calWidth={"260px"}
                    onDateSelect={handleDateSelect}
                    />}
          </div>

          <div>
                <h4>Document Type<span style={{color:'red'}}>*</span></h4>
                <CustomDropdown
                  options={docTypeOptions}
                  selectedOption={doctype}
                  placeholder={"Select document type"}
                  heightFor="42px"
                  paddingFor="0.5em"
                  onSelect={handleDocSelect}
                  marginFor={"10px"}
                />
            </div>
        </div>

        <div className='doc-summary-side'>
          
            <div className='dropdown_main'>
                <h4>Language</h4>

                <div className='lang_drop' onClick={()=>toggleLanguageOpen()}>
                  {selectedLang ?  <h4>{selectedLang} </h4>:
                  
                  <p>Select the appropriate language</p>}

                  {isLanguageOpen ? 
                  <IoIosArrowUp
                  color='#9CA0A5'
                  /> :
                  <IoIosArrowDown
                  color='#9CA0A5'
                  
                  />}
                  
                
                </div>
                
                  {isLanguageOpen && 
                        <div className='create_doc_filter_lang'>
                            <div onClick={()=>handleLanguage("English")}>
                                {selectedLang ==="English" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>English</h3>
                            </div>

                            <div onClick={()=>handleLanguage("French")}>
                            {selectedLang ==="French" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>French</h3>
                            </div>

                            <div onClick={()=>handleLanguage("Portuguese")}>
                            {selectedLang ==="Portuguese" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>Portuguese</h3>
                            </div>

                        </div>
                  }
            </div>

            <div>
                <h4>Location<span style={{color:'red'}}>*</span></h4>
                <CountryDropdown
                  placeholder={"Select location this doc covers"}
                  heightFor="42px"
                  paddingFor="0.5em"
                  marginFor={"10px"}
                  selectedCountry={handleLocation}
                />
            </div>
        </div>


        <div className='doc-summary-side'>
            <div>
                    <h4>Source<span style={{color:'red'}}>*</span></h4>
                    <input 
                    className='doc_summary_cont_source'
                    placeholder="Enter document’s source"
                    type="text"
                    value={docSource}
                    onChange={(e)=>setDocSource(e.target.value)}
                    />
            </div>

            <div>
                <h4>How did you get this document? <span style={{color:'red'}}>*</span></h4>

                <div>
                  <CustomDropdown
                    options={sourceOptions}
                    selectedOption={howDoc}
                    placeholder={"How did you get this document?"}
                     heightFor="42px"
                    paddingFor="0.5em"
                    onSelect={handleDocHow}
                    marginFor={"10px"}
                    
                  />
                </div>
            </div>
        </div>

        <div>
            <h4>Attach the PDF file containing your report.<span style={{color:'red'}}>*</span></h4>

                   {file ? (
                        <>
                             <div className="more_about_one_pp">
                                <div>
                        
                                    <div>
                                        <img src={uploaded} alt="Upload Icon" />
                                    </div>

                                    <p className="click_drag_uploaded">
                                     Upload Successful
                                    </p>
                                                                   
                                    {file && <p className="click_svg">{file.name}</p>}
                                    <div 
                                     onClick={()=>setFile(null)}
                                    className='clear_upload'>
                                    <img 
                                   
                                    src={bin} alt="" />
                                    <h4 style={{margin:'0'}}>Clear upload</h4>
                                </div>
                                </div>

                                
                             
                            </div>
                            
                            

                        </>
                           
                        ):(
                        <>
            
                <div 
                    className="more_about_one_pp"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => document.getElementById('fileInput').click()}
                >
                    <div>
                    
                        <div>
                            <img src={upload} alt="Upload Icon" />
                            </div>

                                <input
                                type="file"
                                id="fileInput"
                                style={{ display: 'none' }}
                                accept=".pdf"
                                onChange={handleFileChange}
                                />

                                <p className="click_drag_upload">
                                Click to upload <span>or drag and drop</span>
                                </p>
                                <p className="click_svg">PDF (max. 10 megabytes)</p>

                                {error && <p className="error-message">{error}</p>}
                              
                        </div>
                    </div>
                    </>)}
                    
        </div>

        <div className='create-doc-btn-cont'>

          <button className='create-doc-btn-save'>
           Save Draft
          </button>

          <button 
          onClick={()=>goToconfirmPage()}
          className='create-doc-btn-next'>
            Next Step
          </button>

        </div>
       

     </form>

    </div>
  )
}

export default DocumentSummary
