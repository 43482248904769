import React,{useState,useEffect} from 'react'
import './GreetingBox.scss';
import { FiRefreshCw } from "react-icons/fi";
import { CiCalendar } from "react-icons/ci";
import Spinner from '../../../Onboarding/Reusables/spinner/spinner';
import { useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa6";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import closeIcon from '../../../../assets/greys.png';
import paper1 from '../../../../assets/newspaperTwo.png';
import paper2 from '../../../../assets/newspaperTwoUn.png';
import check from '../../../../assets/Outer Circle.png';
import checked from '../../../../assets/checked.svg';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 560,
    height: 705,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
    padding:"16px 32px 32px",
    // overflowY:"auto"
   
  };
  

const GreetingBox = () => {

    const userData = useSelector(state => state.userLogin.userInfo);
    const firstName = userData.user.first_name;
    const [timeOfDay, setTimeOfDay] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    const [loading,setLoading]=useState(false);
    const [selectedItem,setSelectedItem] = useState("doc");
    const [open,setOpen] = useState(false)


    useEffect(() => {
        const updateGreeting = () => {
            const now = new Date();
            const hours = now.getHours();

            if (hours < 12) {
                setTimeOfDay('morning');
            } else if (hours < 18) {
                setTimeOfDay('afternoon');
            } else {
                setTimeOfDay('evening');
            }
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            setCurrentDate (now.toLocaleDateString('en-UK', options));
            
        };

        updateGreeting();
    }, []);
    

    return(
    <div className='greeting-box-cont'>
      <div className='greeting-box-cont-one'>
        <h4>
            Good {timeOfDay}, <span>{firstName}</span>🚀
        </h4>

        <div className='greeting-box-cont-rfr'>
        <p>Explore new and personalized content </p>

        <div> 
            {loading ?(
                
                <Spinner
                color={'#007EFF'}
                size={15}
                />
              
            ):(
                <>
                <FiRefreshCw 
                color='#007EFF'
                size={15}
                onClick={()=>setLoading(true)}
                /> 
                <p> Refresh</p> 
                </>
            )}
            
           
        </div>

        </div>
       
      </div>
    
    {userData.user.account_type === "consumer" ? (
        <div className='greeting-box-date'>
        <div className='greeting-box-cal' >
            <CiCalendar 
            size={17}
            />
        </div>
        <div className='greeting-box-day'>
            <p>Today’s Date</p>
            <h4>{currentDate}</h4>
        </div>
      </div>
    ):(
        <button 
        onClick={()=>setOpen(true)}
        className='new-contrbtn'>
            <FaPlus
                 color='#FFFFFF'
                 size={15}
            />
            <p> New Contribution</p>
           
        </button>
    )}

        <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} overflow-x >

                <div style={{textAlign:"right"}}>
                    <img 
                    onClick={()=>setOpen(false)}
                    src={closeIcon} alt="" />
                </div>
                

                <div className='cont_modal_header'>

                    <h2>Add New Contribution</h2>
                    <p>Contributors can add data and reports to the Sonaroid platform. You get paid when your contributions are viewed by others.</p>

                </div>

                <h3 className='cont_modal_sub'> What type of resource are you adding? </h3>

                <div className='cont_modal_content'>

                    <div>
                        <div>
                         { selectedItem === "data" ? 
                            <div>
                                <img src={checked} alt="" />
                            </div>
                             
                            :
                            <div>
                                 <img 
                                onClick={()=>setSelectedItem("data")}
                                src={check} alt="icon" 
                                />
                            </div>
                           
                         }
                        </div>
                        

                        <div>
                            <h4>Add New Data Table</h4>
                            <p>Add a new table by importing CSV or creating manually.</p>
                        </div>
                    </div>
                    

                    <img
                    style={selectedItem === "data" ? {border:'1px solid blue',borderRadius :"4px"} :{} }
                    src={paper1} alt="paperimg" />
                </div>

                <div className='cont_modal_content'>

                    <div>
                        { selectedItem === "table" ? 
                             <div>
                                <img src={checked} alt="" />
                             </div>
                            :
                            <div>
                                <img 
                            onClick={()=>setSelectedItem("table")}
                            src={check} alt="icon" 
                            />
                            </div>
                         }

                        <div>
                            <h4>Update Existing Table</h4>
                            <p>Add new columns or rows to an existing table on Sonaroid.</p>
                        </div>
                    </div>

                   

                    <img
                    style={selectedItem === "table" ?  {border:'1px solid blue',borderRadius :"4px"} :{} }
                    src={paper1} alt="paperimg" />
                </div>

                <div className='cont_modal_content'>

                    <div>

                        { selectedItem === "doc" ? 
                            <div>
                              <img src={checked} alt="" />
                           </div>
                          :
                          <div>
                              <img 
                                onClick={()=>setSelectedItem("doc")}
                                src={check} alt="icon" 
                                />
                          </div>
                         }
                        

                        <div>
                            <h4>Upload Reports</h4>
                            <p> Attach a pdf document</p>
                        </div>
                    </div>

                   

                    <img  
                    style={selectedItem === "doc" ?  {border:'1px solid blue',borderRadius :"4px"}  :{} }
                    src={paper2} alt="paperimg" />
                </div>

                <button 
                onClick={()=>  window.location.href="/contributor/createdoc"}
                className='cont_modal_button'>
                    Proceed
                </button>
              </Box>
        </Modal>
      
    </div>
  )
}

export default GreetingBox
