import React from 'react'
import back from '../../../../assets/backicon.svg';
import reportIcon from '../../../../assets/reportIcon2.svg';
import { useLocation } from 'react-router-dom';
import './PageHeader.scss'

const PageHeader = ({reportTitle,pageName}) => {

    const path = useLocation().pathname;

    function handleBack(){
        if(path.startsWith("/contributor/contributions/")){
            window.location.href="/contributor/contributions/submitted";
        }
        else if(path.startsWith("/consumer/documents/home")){
            window.location.href="/consumer/documents/home";
        }
    }

   

  return (

    <div className='page-header-cont'>

        <div
        onClick={()=> handleBack()}
        >
            <img src={back} alt="" />
        </div>

        <div className='pg-divider'>
            
        </div>

        <div className='pg-header-name'>
            <div>
                <img src={reportIcon} alt="icon" />
                <h4>{pageName}</h4>
            </div>

            <div>
                <h5>/</h5>
            </div>
           
            <div>
            <p>{reportTitle}</p>
            </div>
        
            

        </div>
      
    </div>
  )
}

export default PageHeader
