import React,{ useState, useContext,useEffect} from 'react';
import OnboardingSidebar from '../../Reusables/OnboardingSidebar/OnboardingSidebar';
import Header from '../Header/Header';
import ProgressBar from '../../Reusables/ProgressBar/ProgressBar';
import backIcon from '../../../../assets/backicon.svg';
import './MoreAboutFour.scss';
import { IoMdClose } from "react-icons/io";
import upload from '../../../../assets/file upload states.svg';
import uploaded from '../../../../assets/uploadsucess.svg';
import bin from '../../../../assets/bin.svg'
import BaseButton from '../../Reusables/BaseButton/BaseButton';
import { multiStepContext } from '../../Reusables/ProgressBar/StepContext';


const MoreAboutFour = () => {
  const { setStep } = useContext(multiStepContext);
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [bio,setBio]=useState('');
  const [experience,setExperience]=useState('');
  const [dataaccess,setDataAccess]=useState('');

  const handleBio = (e) => {
    const newBio = e.target.value;
    setBio(newBio);
    sessionStorage.setItem('bio', newBio);
  };
  const handleExperience = (e) => {
    const newExp = e.target.value;
    setExperience(newExp);
    sessionStorage.setItem('experience', newExp);
  };
  const handleDataAccess = (e) => {
    const newData = e.target.value;
    setDataAccess(newData);
    sessionStorage.setItem('data', newData);
  };



  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    handleFileUpload(selectedFile);
  };



  const handleFileUpload = (file) => {
    if (file && file.type === 'application/pdf') {
      if (file.size <= 4 * 1024 * 1024) { // 4MB in bytes
        setFile(file);
        setError('');
      } else {
        setError('File size exceeds 4MB');
      }
    } else {
      setError('Please upload a valid PDF file');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    handleFileUpload(droppedFile);
  };
  const handleSubmit=()=>{
    setStep(6)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  }

  
  useEffect(()=>{

   
    setBio(sessionStorage.getItem('bio') || '');
    setExperience(sessionStorage.getItem('experience') || '');
    setDataAccess(sessionStorage.getItem('data') || '')
    
   

  },[])
  return (
    <div className='onboard_cont'>
        <div>
            <OnboardingSidebar/>
        </div>

        <div className='more_about_four_cont'>
            
            <img 
            src={backIcon} 
            onClick={()=>setStep(3)}
            style={{marginBottom:'32px'}}
            alt="" />
            <div>
                <Header/>
            </div>
            <div>
                <ProgressBar/>
            </div>

            <div>
                <h3 className='more_about_four_header'>Credibility Check</h3>

                <form action="">
                    <div className='more_about_four_text' >
                        <p className='more_about_four_text_header'>A short bio of yourself</p>
                        <div>
                            <textarea 
                            name="" 
                            id=""
                            required
                            onChange={(e)=>handleBio(e)}
                            placeholder='I am a bioengineering researcher at Yale with 7 years of research experience.'
                            value={bio}
                            ></textarea>
                            <IoMdClose color='#9CA0A5'/>
                        </div>
                        <p className='more_about_four_text_helper'>Maximum of 150 words</p>
                    </div>

                    <div  className='more_about_four_text'>
                        <p className='more_about_four_text_header' >Your previous experience as a contributor to energy data</p>
                        <div>
                            <textarea 
                            name="" 
                            id=""
                            required
                            onChange={(e)=>handleExperience(e)}
                            placeholder='I am a bioengineering researcher at Yale with 7 years of research experience.'
                            value={experience}
                            ></textarea>
                            <IoMdClose color='#9CA0A5'/>
                        </div>
                        <p className='more_about_four_text_helper'>Maximum of 150 words</p>
                    </div>
                    
                    <div  className='more_about_four_text'>
                        <p className='more_about_four_text_header'>How do you have access to the data you will provide?</p>
                        <div>
                            <textarea 
                            name="" 
                            id=""
                            required
                            value={dataaccess}
                            onChange={(e)=>handleDataAccess(e)}
                            placeholder='I am a bioengineering researcher at Yale with 7 years of research experience.'
                            ></textarea>
                            <IoMdClose color='#9CA0A5'/>
                        </div>
                        <p className='more_about_four_text_helper'>Maximum of 150 words</p>
                    </div>

                    <div className='more_about_four_doc'>
                        <p>Upload supporting documents to back your claims</p>

                        {file ? (
                        <>
                             <div className="more_about_one_pp">
                                <div>
                        
                                    <div>
                                        <img src={uploaded} alt="Upload Icon" />
                                    </div>

                                    <p className="click_drag_uploaded">
                                     Upload Successful
                                    </p>
                                                                   
                                    {file && <p className="click_svg">{file.name}</p>}
                                    <div 
                                     onClick={()=>setFile(null)}
                                    className='clear_upload'>
                                    <img 
                                   
                                    src={bin} alt="" />
                                    <h4>Clear upload</h4>
                                </div>
                                </div>

                                
                             
                            </div>
                            
                            

                        </>
                           
                        ):(
                        <>

                        <div 
                            className="more_about_one_pp"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            onClick={() => document.getElementById('fileInput').click()}
                           
                        >
                            <div>
                    
                            <div>
                            <img src={upload} alt="Upload Icon" />
                            </div>

                                <input
                                type="file"
                                id="fileInput"
                                style={{ display: 'none' }}
                                accept=".pdf"
                                onChange={handleFileChange}
                                />

                                <p className="click_drag_upload">
                                Click to upload <span>or drag and drop</span>
                                </p>
                                <p className="click_svg">PDF (max. 4 megabytes)</p>

                                {error && <p className="error-message">{error}</p>}
                              
                        </div>
                        </div>
                    
                        </>
                        )}
                        
                        <h4>Put all supporting documents in a single pdf.</h4>
                    </div>

                    <BaseButton
                        title={"Proceed"}
                        width={"100%"}
                        height={"44px"}
                        padding={"0.5em 1em"}
                        onClick={()=>handleSubmit()}
                    />
                   
                </form>
            </div>
        </div>
    </div>
  )
}

export default MoreAboutFour
