import React,{useEffect, useState} from 'react';
import logo from '../../../../assets/logocontributor.png';
import './ContributorSidebar.scss';
import discover2 from '../../../../assets/discoverIcon2.svg';
import news1 from '../../../../assets/loudspeaker.svg';
import data1 from '../../../../assets/bar-chart-v.svg';
import setting from '../../../../assets/settingsTwo.svg';
import logoutIcon from '../../../../assets/exiticon.svg'
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";


const ContributorSidebar = () => {
  
  const userData = useSelector(state => state.userLogin.userInfo);
  // const token = userData.token
  // const userEmail = userData.user.email;
  
  const firstName = userData.user.first_name;
  // const lastName = userData.user.last_name;
  // const fullName = firstName + " " + lastName;
    const [displayDrop,setDisplay]=useState(false)
    const [contriDrop,setContriDrop]=useState(false)
    const { pathname } = useLocation();

    const toggleDisplay =()=>{
      setDisplay(!displayDrop)
    };

    const toggleContributor =()=>{
      setContriDrop(!contriDrop)
    }
    const sideBarLinks = [
        {
          name: "Discover",
          path: "/contributor/dashboard",
          icon1: discover2 ,
        
        },
        {
          name: "News",
          path: "/contributor/news/all",
          icon1: news1,
         
        }
        
      ];


    const settingLinks = [
        {
          name: "Profile",
          path: "/contributor/profile",
        },
     
        {
          name: "Activity",
          path: "/contributor/wallet",
        },
           {
          name: "Wallet",
          path: "/contributor/wallet",
        },
         
      ];


      const contributionLinks = [
        {
          name: "Submitted Contributions",
          path: "/contributor/contributions/submitted",
        },
     
        {
          name: "Draft Contributions",
          path: "/contributor/contributions/draft",
        }
      ];

  const handleLogout=()=>{

    localStorage.clear();
    sessionStorage.clear();
    window.location = "/";
  }

  useEffect(()=>{   
      if(pathname === "/contributor/contributions/submitted" || pathname === "/contributor/contributions/drafts"){
        setContriDrop(true)
      }
    // eslint-disable-next-line
    },[pathname]);
  return (
    

    
    <div className='contributor-sdb-cont'>

      <div className="sidebar-content">

   
      <div className='contributor-sdb-cont-logo'>
        <img src={logo} alt="" />
      </div>

       {sideBarLinks.map((item, i) => (
        <Link style={{textDecoration:"none"}}  to={item.path} key={i}>
        <div className={pathname.startsWith(item.path) ? 'contributor-sdb-cont-pg alt':'contributor-sdb-cont-pg'}>
            <div>
             <img 
             src={item.icon1}
             alt={item.name}
             />
            </div>
            
            <h4>{item.name}</h4>
        </div>
        </Link>
       ))}


          <div>
            <div 
            onClick={()=>toggleContributor()}
            style={{margin:"20px 0px"}}
            className='contributor-sdb-cont-sts'>
                <div>
                    <div>
                        <img src={data1} alt="" />
                    </div>
                    <h4
                    
                    >Contributions</h4>
                </div>
                {contriDrop ?
                 <IoIosArrowUp 
                 color='#FFFFFF' 
                 size={20}/>
                :
               
                <IoIosArrowDown
                color='#FFFFFF' 
                size={20}
                />
                }

            </div>
            
            {contriDrop ? (
              <>
              {contributionLinks.map((item,i)=>(
                <Link style={{textDecoration:"none"}}  to={item.path} key={i}>
                <div 
                className={pathname===item.path ? 'contributor-sdb-cont-prf alt':'contributor-sdb-cont-prf'}
                // className='contributor-sdb-cont-prf'
                >   
                    <h4>{item.name}</h4>
                </div>
                </Link>
               
              ))}
              </>
            ):(
              ""
            )}
            
            
           
       </div>

        <hr />

       <div>
            <div 
            onClick={()=>toggleDisplay()}
            
            className='contributor-sdb-cont-sts'>
                <div>
                    <div>
                        <img src={setting} alt="" />
                    </div>
                    <h4
                    
                    >Settings</h4>
                </div>
                {displayDrop ?
                 <IoIosArrowUp 
                 color='#FFFFFF' 
                 size={20}/>
                :
               
                <IoIosArrowDown
                color='#FFFFFF' 
                size={20}
                />
                }

            </div>
            
            {displayDrop ? (
              <>
              {settingLinks.map((item,i)=>(
                <Link style={{textDecoration:"none"}}  to={item.path} key={i}>
                <div 
                // className={pathname===item.path ? 'contributor-sdb-cont-pg alt':'contributor-sdb-cont-prf'}
                className='contributor-sdb-cont-prf'
                >   
                    <h4>{item.name}</h4>
                </div>
                </Link>
               
              ))}
              </>
            ):(
              ""
            )}
            
            
           
       </div>

       </div>

       <div className='contributor-sdb-logout'>
            <div>
              <div className='contributor-sdb-logout-img' >
                <img src="https://images.pexels.com/photos/709143/pexels-photo-709143.jpeg?auto=compress&cs=tinysrgb&w=600" alt="profile" />
              </div>

              <div className='contributor-sdb-logout-mail'>
                <h4>{firstName}</h4>
                {/* <p>{userEmail}</p> */}
              </div>
            </div>

            <div className='contributor-sdb-logout-icon'>
              <img 
              onClick={()=>handleLogout()}
              src={logoutIcon} alt="icon"/>
            </div>
            
       </div>
      
    </div>
    
  )
}

export default ContributorSidebar
