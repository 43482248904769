import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import './ReportPagination.scss'
const PaginationReport = ({ totalItems , defaultItemsPerPage = 10 }) => {
  // State variables
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  // Total pages based on API data
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Handle "Previous" button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Handle "Next" button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // Handle page click
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  // Handle "Show per page" dropdown change
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page
  };

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // Render pagination numbers dynamically with ellipsis
  const renderPageNumbers = () => {
    if (totalPages <= 5) {
      return pageNumbers.map((num) => (
        <button
          key={num}
          className={`page-number ${currentPage === num ? "active" : ""}`}
          onClick={() => handlePageClick(num)}
        >
          {num}
        </button>
      ));
    }
    

    const firstPage = 1;
    const lastPage = totalPages;
    const middlePages = pageNumbers.slice(Math.max(currentPage - 2, 1), currentPage + 1);

    return (
      <div>
        <button
          className={`page-number ${currentPage === firstPage ? "active" : ""}`}
          onClick={() => handlePageClick(firstPage)}
        >
          {firstPage}
        </button>
        {currentPage > 3 && <span className="ellipsis">...</span>}
        {middlePages.map((num) => (
          <button
            key={num}
            className={`page-number ${currentPage === num ? "active" : ""}`}
            onClick={() => handlePageClick(num)}
          >
            {num}
          </button>
        ))}
        {currentPage < totalPages - 2 && <span className="ellipsis">...</span>}
        <button
          className={`page-number ${currentPage === lastPage ? "active" : ""}`}
          onClick={() => handlePageClick(lastPage)}
        >
          {lastPage}
        </button>
      </div>
    );
  };

  return (
    <div className="report-pagination-main">
    <div className="report-pagination-container">
      <div className="report-pagination-controls">
        <p className="report-pagination-label" >Show per page</p>
        <select
          id="itemsPerPage"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
        >
          {[10, 20, 50].map((num) => (
            <option key={num} value={num}>
              {num}
            </option>
          ))}
        </select>
      </div>
      <div>
      {renderPageNumbers()}
      </div>
     
      <div className="report-pagination">
        <div className="pagination-btn-report">
            <IoIosArrowBack
            size={20}
            color={currentPage === 1 ? '#9CA0A5':'#4B4F57'}
            />
            <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            
            >
            Previous
            </button>
        </div>
      
      
        <div
        className="pagination-btn-report">
            <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            
            >
            Next
            </button>
            <IoIosArrowForward
                color={currentPage === totalPages ? '#9CA0A5':'#4B4F57'}
             size={20}
            />
        </div>
      </div>
    </div>
    </div>
  );
};

export default PaginationReport;
