import React,{useContext} from 'react'
import './CreateDocSideBar.scss';
import { multiStepContext } from '../../../Onboarding/Reusables/ProgressBar/StepContext';

const CreateDocSideBar = () => {

   const { currentstep } = useContext(multiStepContext);

  return (

    <div 
    
    className={currentstep === 1 ? 'create-doc-side-cont':'create-doc-side-cont alt'}>

      <div >

        <div className='create-doc-side-header'>

          <div className={`create-doc-side-num ${currentstep >= 1 ? 'active' : ''}`}>
            <h4>1</h4>
          </div>
          <div className={`create-doc-side-content ${currentstep >= 1 ? 'active' : ''}`}>
            <h4>Report Summary</h4>
            <p>Add some details to describe your contribution.</p>
          </div>

        </div>

        <div className='create-doc-side-header'>

          <div className={`create-doc-side-num ${currentstep > 1 ? 'active' : ''}`}>
            <h4>2</h4>
          </div>
          <div className={`create-doc-side-content ${currentstep > 1 ? 'active' : ''}`}>
            <h4>Submit for Review</h4>
            <p>Send your contribution for review.</p>
          </div>

        </div>

      </div>

      <div className='create-doc-supp-txt'>
        <h4>Need Help?</h4>
        <p>Get to know how to prevent errors when adding a contribution.</p>

        <div>
          <h4>See Guides</h4>
        </div>
      </div>

    </div>
  )
}

export default CreateDocSideBar
