import React from 'react';
import img1 from "../../../../assets/standards.png";
import img2 from '../../../../assets/analysis.png';
import img3 from '../../../../assets/regulations.png';
import img4 from '../../../../assets/guidelines.png';
import img5 from '../../../../assets/research.png';
import img6 from '../../../../assets/periodicals.png';
import bookmark from '../../../../assets/bookmarks.svg';
import bookmarked from '../../../../assets/bookmarkblue.svg'
import shareIcon from '../../../../assets/share.png';
import { IoLocationOutline } from "react-icons/io5";
import { IoLanguage } from "react-icons/io5";
import { FaHashtag } from "react-icons/fa6";
import { CiCalendar } from "react-icons/ci";
import eyeImg from '../../../../assets/eyeIcon.png';
import './ReportsCard.scss';
import axios from 'axios';
import { useSelector } from 'react-redux';

const ReportsCard = ({apiDocuments,reloadPage}) => {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;

  function iconType(value){
    if(value === 'Standards')
    {
        return img1
    }
    else if(value === 'Analysis'){
        return img2
    }
    else if(value === 'Regulations'){
        return img3
    }
    else if(value === 'Research'){
      return img4
    }
    else if(value === 'Periodicals'){
      return img5
    }
    else {
      return img6
    }
  }
  
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options); 
  }

  const addBookmark = async (value) => {

       
    try {
     
      const config = {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
      };

      axios
        .get(`${baseUrl}documents/consumer/${value}/bookmark`,config)
        .then((res) => {
            console.log(res)
            alert(res.data.message)
            reloadPage()
           
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            alert(error.response.data.message);
          } else if (error.request) {
            alert("Check your connection. Unable to reach the server.");
          } else {
            alert("An error occurred. Please try again later.");
          }
        });
    }
    catch (error) {
     
      console.log("error", error);
      if (error.response) {
          if (error.response.data.message === "Please login again.") {
              localStorage.clear(); // Clear local storage
              window.location.href = '/login'; // Redirect to login page
          } else {
              alert(error.response.data.message);
          }
      } else if (error.request) {
          alert("Check your connection. Unable to reach the server.");
      } else {
          alert("An error occurred. Please try again later.");
      }
    }
  
   
}


  return (
    <section className='reports_main'>

    {apiDocuments.map((item,index)=>(

      <div 
    
      className='reports_content_cont' key={index} >


        <div>
            <img src={iconType(item.document_type)} alt="icon" />
        </div>

        <div className='reports_content_main'>


            <div className='reports_title_cont'>
                <h5>{item.document_type.toUpperCase()}</h5>

                <div>
                    <img src={shareIcon} alt="" />
                    {item.is_bookmarked ?
                      <img 
                      onClick={()=>addBookmark(item.id)}
                      src={bookmarked} alt="" />
                      :
                      <img 
                      onClick={()=>addBookmark(item.id)}
                      src={bookmark} alt="" />
                      }
                </div>
            </div>

            <h4 onClick={()=>window.location.href=`/consumer/documents/home/viewdocument/${item.id}`}>{item.title}</h4>

            <h5>{item.author}</h5>

            <p>{item.description}</p>

            <div className='reports_add_info'>

                <div className='reports_tags'>
                    <div>
                        <IoLocationOutline
                        color='#9CA0A5'
                        />
                        <h4>{item.location}</h4>
                    </div>
                    <div>
                        <IoLanguage
                        color='#9CA0A5'
                        />
                        <h4>{item.language}</h4>
                    </div>
                    <div>
                        <FaHashtag
                        color='#9CA0A5'
                        />
                        {item.industries.map((tags,index)=>(

                            <h4 key={index}>{tags.name}</h4>
                        ))}
                        
                    </div>
                    <div>
                       <CiCalendar
                       color='#9CA0A5'
                       />
                        <h4>{formatDate(item.publication_date)}</h4>
                    </div>
                </div>

                <div className='reports_view'>
                    <img src={eyeImg} alt="" />
                    <h4>{item.total_views}</h4>
                </div>
               
            </div>


        </div>
       
      </div>
      

    ))}

    </section>
  )
}

export default ReportsCard
